import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
  providedIn: 'root'
})


export class AuthService {
  private isAuthenticated = false;
  constructor(private cookieService : CookieService) {
    
  }
  isLoggedIn(): boolean {
   
      const user = this.cookieService.get('name');
      const auth = this.cookieService.get('auth');
      const isLoggedIn = this.cookieService.get('isLoggedIn');      
      if (isLoggedIn === 'true' && auth !== '' && user !==''){
        this.isAuthenticated = true;
        return true;
      }else{
        return false;
      }
  }
  isAuthenticatedUser(): boolean {
    return this.isAuthenticated;
  }

  logout() {
    this.cookieService.delete('userId');
    this.cookieService.delete('name');
    this.cookieService.delete('auth');
    this.cookieService.delete('userGroup');
    this.cookieService.delete('customer');
    this.cookieService.delete('isLoggedIn');
    this.isAuthenticated = false;
  }


}
