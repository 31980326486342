<div class="layout-topbar">
    <a class="layout-topbar-logo" routerLink="/">
        <img src="assets/layout/images/{{layoutService.config.colorScheme === 'light' ? 'logo' : 'logo'}}.png"
            alt="SiriNEO Things - IoT Web Platform" title="SiriNEO Things - IoT Web Platform">
    </a>
    
    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button"
        (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>
    <!--
    <span class="form-inline p-link layout-topbar-button">
        <span class="nav-link"></span>
        <select (change)="switchLang($event)">
            <option *ngFor="let lang of langs | keyvalue" [value]="lang.key" [selected]="lang.key === currentLang">
                {{lang.value}} </option>
        </select>
    </span>
    -->
    <div class="flex justify-content-center align-items-center">
         
    </div>
    <div #topbarmenu class="layout-topbar-menu"
        [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">        
        <span class="p-link form-inline divider"></span>
        <p-selectButton 
            [options]="langOptions" 
            [(ngModel)]="selectedLanguage"       
            (click)="switchLang($event)">
            <ng-template let-item>
            <i [class]="item.icon"></i><i [class]="item.label"></i>
            </ng-template>        
        </p-selectButton>
        <span class="p-link form-inline"></span>
        <button class="p-link layout-topbar-button">
            <i class="pi pi-building"></i>
            <span>Empresa</span>
        </button>
        <button class="p-link layout-topbar-button">
            <i class="pi pi-user-edit"></i>
            <span>Profile</span>
        </button>
        <button class="p-link form-inline">
            <i class="pi pi-bell " pBadge value="10" style="font-size: 20px; margin-right: 12px; margin-top: 0px;"></i>            
        </button>
        <button class="p-link layout-topbar-button" (click)="logout()" title="">
            <i class="pi pi-power-off"></i>
            <span>Logout</span>
        </button>
    </div>
</div>