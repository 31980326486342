<!--
<ul class="layout-menu">    
    <ng-container *ngFor="let item of model; let i = index;">
        <li app-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
        <li *ngIf="item.separator" class="menu-separator"></li>
    </ng-container>    
</ul>
-->

<p-accordion [multiple]="true">
    <p-accordionTab header="{{customer}}" [selected]="true"><!--{{'dashboard'|translate}}-->
        <ul class="layout-menu">
            <ng-container *ngFor="let item of dashboards; let i = index;">
                <li app-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
                <li *ngIf="item.separator" class="menu-separator"></li>
            </ng-container>
        </ul>
    </p-accordionTab>
    <p-accordionTab header="{{'entity'|translate}}" [selected]="true">
        <ul class="layout-menu">
            <ng-container *ngFor="let item of entity; let i = index;">
                <li app-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
                <li *ngIf="item.separator" class="menu-separator"></li>
            </ng-container>
        </ul>
    </p-accordionTab>
    <p-accordionTab header="{{'resources'|translate}}" [selected]="false">
        <ul class="layout-menu">
            <ng-container *ngFor="let item of resources; let i = index;">
                <li app-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
                <li *ngIf="item.separator" class="menu-separator"></li>
            </ng-container>
        </ul>
    </p-accordionTab>
    <p-accordionTab header="{{'administration'|translate}}" [selected]="false">
        <ul class="layout-menu">
            <ng-container *ngFor="let item of administration; let i = index;">
                <li app-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
                <li *ngIf="item.separator" class="menu-separator"></li>
            </ng-container>
        </ul>
    </p-accordionTab>
    <!--<p-accordionTab header="Header II">
        <p-accordion [multiple]="true">
            <p-accordionTab header="Header A">
                <div style="color: red">
                    <p>A-0</p>
                    <p>A-1</p>
                    <p>A-2</p>
                    <p>A-3</p>
                    <p>A-4</p>
                    <p>A-5</p>
                </div>
            </p-accordionTab>
            <p-accordionTab header="Header B">
                <div style="color: green; padding-left: 5rem;">
                    <p>B-0</p>
                    <p>B-1</p>
                    <p>B-2</p>
                    <p>B-3</p>
                    <p>B-4</p>
                    <p>B-5</p>
                </div>
            </p-accordionTab>
        </p-accordion>
    </p-accordionTab>-->
</p-accordion>