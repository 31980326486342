<ngx-loading-bar class="progress-bar-loading" height="4px"></ngx-loading-bar>
<router-outlet></router-outlet>
<p-dialog [(visible)]="sessionDialog" header="{{'finishSession'|translate}}" [modal]="true" [style]="{width:'450px'}">
    <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
        {{idleState}}        
    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple icon="pi pi-times" class="p-button-text" label="{{'finishSession'|translate}}" (click)="logout()"></button>
        <button pButton pRipple icon="pi pi-check" class="p-button-text" label="{{'return'|translate}}" (click)="resetSession()"></button>
    </ng-template>
</p-dialog>
