import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig, SelectItem } from 'primeng/api';
import { LayoutService } from "./service/app.layout.service";


@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})


export class AppTopBarComponent implements OnInit{

    //langs: any = { 'pt': 'Português', 'en': 'English' };
    currentLang: any;
    userName: string;   
    langOptions: any[];
    selectedLanguage: string;

    items!: MenuItem[];
    @ViewChild('menubutton') menuButton!: ElementRef;
    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;
    @ViewChild('topbarmenu') menu!: ElementRef;
   

    constructor(
        public layoutService: LayoutService,
        private auth: AuthService,   
        private router: Router,
        public translateService: TranslateService,
        private primeNGConfig: PrimeNGConfig) {
            this.langOptions = [
                { 
                    label: 'Português', 
                    value: 'pt',
                    icon: 'flag flag-br' 
                },
                { 
                    label: 'English', 
                    value: 'en',
                    icon: 'flag flag-us'
                }           
            ];     
        this.translateService.addLangs(Object.keys(this.langOptions));        
        this.translateService.setDefaultLang(translateService.getBrowserLang());
        this.currentLang = translateService.getBrowserLang();
        this.translateService
            .get('primeng')
            .subscribe(res => this.primeNGConfig.setTranslation(res));
    }

    ngOnInit(){        
        this.primeNGConfig.ripple = true;
        this.selectedLanguage =  localStorage.getItem("language") || this.translateService.getBrowserLang();    
    }

    switchLang($event: any){
        this.translateService.use(this.selectedLanguage);
        this.currentLang = this.selectedLanguage
        this.translateService
            .get('primeng')
            .subscribe(res => this.primeNGConfig.setTranslation(res));
        localStorage.setItem("language",this.selectedLanguage);
        //location.reload();
    }

    logout(): void {
        this.auth.logout();
        this.router.navigate(['/login']);
    }

    /*switchLang(e: any) {
        //Inform @ngx-translate about language change
        this.translateService.use(e.target.value);
        this.currentLang = e.target.value;
        //console.log(e);
        this.translateService
            .get('primeng')
            .subscribe(res => this.primeNGConfig.setTranslation(res));
    }*/
   
}
