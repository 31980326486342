import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { NotfoundComponent } from './st/components/notfound/notfound.component';
import { AppLayoutComponent } from "./layout/app.layout.component";
import { AuthGuard } from './auth.guard';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppLayoutComponent,
                children: [                    
                    { path: 'uikit', loadChildren: () => import('./demo/components/uikit/uikit.module').then(m => m.UIkitModule) },
                    { path: 'utilities', loadChildren: () => import('./demo/components/utilities/utilities.module').then(m => m.UtilitiesModule) },
                    { path: 'documentation', loadChildren: () => import('./demo/components/documentation/documentation.module').then(m => m.DocumentationModule) },
                    { path: 'blocks', loadChildren: () => import('./demo/components/primeblocks/primeblocks.module').then(m => m.PrimeBlocksModule) },
                    { path: 'pages', loadChildren: () => import('./demo/components/pages/pages.module').then(m => m.PagesModule) },
                    /** SiriNEO Things */
                    { path: '', loadChildren: () => import('./st/components/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate : [AuthGuard]},
                    { path: 'dashboard', loadChildren: () => import('./st/components/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate : [AuthGuard]},
                    { path: 'device', loadChildren: () => import('./st/components/device/device.module').then(m => m.DeviceModule)},
                    { path: 'customer', loadChildren: () => import('./st/components/customer/customer.module').then(m => m.CustomerModule), canActivate : [AuthGuard] },
                    { path: 'networktype', loadChildren: () => import('./st/components/networktype/networktype.module').then(m => m.NetworktypeModule), canActivate : [AuthGuard] },
                    { path: 'networkserver', loadChildren: () => import('./st/components/networkserver/networkserver.module').then(m => m.NetworkserverModule), canActivate : [AuthGuard] },
                    { path: 'mqttbroker', loadChildren: () => import('./st/components/mqttbroker/mqttbroker.module').then(m => m.MqttbrokerModule), canActivate : [AuthGuard] },
                    { path: 'manufacturer', loadChildren: () => import('./st/components/manufacturer/manufacturer.module').then(m => m.ManufacturerModule), canActivate : [AuthGuard] },
                    { path: 'usergroup', loadChildren: () => import('./st/components/usergroup/usergroup.module').then(m => m.UsergroupModule), canActivate : [AuthGuard] },
                    { path: 'panel', loadChildren: () => import('./st/components/panel/panel.module').then(m => m.PanelModule), canActivate : [AuthGuard] },
                ]
            },            
            { path: 'auth', loadChildren: () => import('./st/components/auth/auth.module').then(m => m.AuthModule) },
            { path: 'landing', loadChildren: () => import('./demo/components/landing/landing.module').then(m => m.LandingModule), canActivate : [AuthGuard] },
            { path: 'login', loadChildren: () => import('./st/components/auth/login/login.module').then(m => m.LoginModule) },
            { path: 'notfound', component: NotfoundComponent },
            { path: '**', redirectTo: '/notfound' },
        ], { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
