import { Component, HostBinding, OnInit } from '@angular/core';
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import { PrimeNGConfig } from 'primeng/api';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import {Keepalive} from '@ng-idle/keepalive';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    @HostBinding('attr.app-version') appVersionAttr = environment.appVersion;
    idleState = this.translateService.instant('sessionRestarted');
    timedOut = false;
    timed: boolean = false;
    sessionDialog: boolean = false;
    constructor(
        private primengConfig: PrimeNGConfig, 
        private translateService :  TranslateService,
        private authService: AuthService, 
        private router: Router,
        private idle: Idle, private keepalive: Keepalive       
    ) { 
        idle.setIdle(120);
        idle.setTimeout(30);
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        idle.onIdleEnd.subscribe(() => {
            this.idleState = this.translateService.instant('sessionRestarted');
            this.sessionDialog = false;
        });
        idle.onTimeout.subscribe(() => {
            this.idleState = this.translateService.instant('timeout');
            this.sessionDialog = false;
            this.timedOut = true;
            this.logout();
        });

        idle.onIdleStart.subscribe(() => (this.idleState = this.translateService.instant('sessionRestarted')));
        idle.onTimeoutWarning.subscribe(countdown => {
            this.sessionDialog = true;
            this.idleState = this.translateService.instant('messageTimeout', {time: countdown});//"You will timevxc out in " + countdown + " seconds!";            
            if (countdown == 1) {
                this.timed = true;               
            }
        });
        //idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
        //idle.onTimeoutWarning.subscribe((countdown) => this.idleState = 'You will time out in ' + countdown + ' seconds!');
        //keepalive.interval(15);
        this.reset();

    }

    resetSession(){
        this.reset();
    }
    
    ngOnInit() {
        this.primengConfig.ripple = true;        
    }
    
    logout(): void {
        this.timed = true; 
        this.authService.logout();
        this.router.navigate(['/login']);
    }

    reset() {
        this.idle.watch();
        this.idleState = this.translateService.instant('sessionRestarted');
        this.timedOut = false;
    }
}
