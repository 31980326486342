<div class="surface-ground flex align-items-center justify-content-center col-sm-12 col-lg-6 col-xl-6">
    <div class="flex flex-column align-items-center justify-content-center">
        <img src="assets/layout/images/logo_st.png"
            alt="SiriNEO Things" class="mb-5 flex-shrink-0">
        <div style="border-radius:4px; padding:0.2rem; background: linear-gradient(180deg, var(--primary-color) 10%, rgba(117, 130, 34, .2) 20%);" class="p-col-6">
            <div class="w-full surface-card py-3 px-2 sm:px-5" style="border-radius:2px">
                <div class="text-center mb-5">
                    <p-header>
                        <span class="text-blue-500 font-bold text-3xl">404</span>
                        <h1 class="text-900 font-bold text-3xl lg:text-5xl mb-2">O recurso não foi encontrado</h1>
                        <div class="text-600 mb-5">Esta é uma versão demonstrativa da aplicação que está em desenvolvimento.</div>
                    </p-header>

                </div>                                            
                <a [routerLink]="['/']" class="w-full flex align-items-center py-5 border-300 border-bottom-1">
                    <span class="flex justify-content-center align-items-center bg-cyan-400 border-round" style="height:3.5rem; width:3.5rem;">
                        <i class="text-50 pi pi-fw pi-table text-2xl"></i>
                    </span>
                    <span class="ml-4 flex flex-column">
                        <span class="text-900 lg:text-xl font-medium mb-0 block">As funcionalidades estão sendo implementadas.</span>
                        <span class="text-600 lg:text-xl"></span>
                    </span>
                </a>
                <a [routerLink]="['/']" class="w-full flex align-items-center py-5 border-300 border-bottom-1">
                    <span class="flex justify-content-center align-items-center bg-orange-400 border-round" style="height:3.5rem; width:3.5rem;">
                        <i class="pi pi-fw pi-question-circle text-50 text-2xl"></i>
                    </span>
                    <span class="ml-4 flex flex-column">
                        <span class="text-900 lg:text-xl font-medium mb-0">Os requisitos e regras de negócios estão sendo aprimoradas conforme as demandas.</span>
                        <span class="text-600 lg:text-xl"></span>
                    </span>
                </a>
                <a [routerLink]="['/']" class="w-full flex align-items-center mb-5 py-5 border-300 border-bottom-1">
                    <span class="flex justify-content-center align-items-center bg-indigo-400 border-round" style="height:3.5rem; width:3.5rem;">
                        <i class="pi pi-fw pi-unlock text-50 text-2xl"></i>
                    </span>
                    <span class="ml-4 flex flex-column">
                        <span class="text-900 lg:text-xl font-medium mb-0">Clique aqui e acesse a plataforma para testar as funcionalidades já implementadas.</span>
                        <span class="text-600 lg:text-xl"></span>
                    </span>
                </a>
            </div>
        </div>
    </div>
</div>