
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { HashLocationStrategy, LOCATION_INITIALIZED, LocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/app.layout.module';
import { NotfoundComponent } from './st/components/notfound/notfound.component';
import { ProductService } from './demo/service/product.service';
import { CountryService } from './demo/service/country.service';
import { CustomerService } from './demo/service/customer.service';
import { EventService } from './demo/service/event.service';
import { IconService } from './demo/service/icon.service';
import { NodeService } from './demo/service/node.service';
import { PhotoService } from './demo/service/photo.service';
import { DialogModule } from 'primeng/dialog';
import { BrowserModule } from '@angular/platform-browser';
// for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
// for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { TranslateModule, TranslateLoader, MissingTranslationHandler, TranslateService, MissingTranslationHandlerParams } from '@ngx-translate/core';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { customTranslate } from './i18n/customTranslate.loader';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { RippleModule } from 'primeng/ripple';
import { ButtonModule } from 'primeng/button';
import { take } from 'rxjs';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, 'assets/i18n/', '.json');
  }
export class TranslateHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams) {
      
    }
  }
export function appInitializerFactory(translateService: TranslateService, injector: Injector): () => Promise<any> {    
    return () => new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      locationInitialized.then(() => {
        translateService.use(localStorage.getItem("language") || window.navigator.language)        
          .pipe(take(1))
          .subscribe(() => {},
          err => console.error(err), () => resolve(null));
      });
    });
}

@NgModule({
    declarations: [
        AppComponent, NotfoundComponent
    ],
    imports: [
        AppRoutingModule,
        AppLayoutModule,
        BrowserModule,
        DialogModule,
        RippleModule,
        ButtonModule,
        // for HttpClient use:
        LoadingBarHttpClientModule,
        // for Router use:
        LoadingBarRouterModule,
        // for Core use:
        LoadingBarModule,
        AppRoutingModule,
        NgIdleKeepaliveModule.forRoot(),HttpClientModule,
        // Initializing TranslateModule with loader
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader, // Main provider for loader
                useClass: customTranslate, // Custom Loader
                useFactory: (HttpLoaderFactory),
                deps: [HttpClient], // Dependencies which helps serving loader
            },
            isolate: false,
            /*missingTranslationHandler: [{
                provide: MissingTranslationHandler, 
                useClass: TranslateLoader
            }]*/
})

    ],
    providers: [
        { 
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            
            deps: [TranslateService, Injector],
            multi: true
        },
        CountryService, CustomerService, EventService, IconService, NodeService,
        PhotoService, ProductService
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }

